@use "variables";
@use "util";

// https://www.joshwcomeau.com/css/custom-css-reset/
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  font-size: 17px;

  @media (max-width: 961px) {
    font-size: 19px;
  }
}
body {
  width: 100%;
  margin: 0;
  font: 400 1rem/1.33 et-book, Palatino, "Palatino Linotype", "Palatino LT STD",
    "Book Antiqua", Georgia, serif;
  font-variant-numeric: oldstyle-nums stacked-fractions;
  /*
  @media (max-width: 961px) {
    line-height: 1.3;
  }
  */
}

h1 {
  margin-top: var(--space-4);
  font-size: var(--font-size-huge);
}

h2 {
  font-size: var(--font-size-large);
  position: relative;
  /* border-bottom: 1px solid var(--color-border); */
}

h2::before {
  content: "";
  position: absolute;
  background-color: var(--color-border);
  width: 90%;
  height: 1px;
  bottom: 0;
  left: 0;

  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  break-inside: avoid;

}

.nota-bene {
  font-weight: 700;
  padding-right: var(--space-2);
  /* border-bottom: 1px solid var(--color-border); */
  /* text-decoration-line: underline; */
  /* text-decoration-color: var(--color-border); */
}

h3 {
  font-size: var(--font-size-normal);
}

h4 {
  font-size: var(--font-size-small);
}

h1,
h2,
h3,
h4 {
  color: var(--color-heading);
}

p {
  padding: 0;
  margin: 0;
  margin-bottom: var(--space-2);

  img {
    margin: var(--space-4) auto;
    display: block;
    height: auto;
  }
}

table {
  margin: var(--space-1) calc(-1 * var(--space-2)) var(--space-2)
    calc(-1 * var(--space-2));
  border-spacing: var(--space-2) 0;
}

li:not(:first-child) {
  padding-top: var(--space-half);
}

a,
a:link,
a:active,
a:visited {
  color: var(--color-primary);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

blockquote footer {
  text-align: right;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

.article-content {
  max-width: 900px;
  margin-bottom: 6rem;
}

div.highlight {
  margin: 1em 0;
}

.big {
  width: 95%;
  @media (min-width: 961px) {
    width: 60%;
  }
}

code,
pre {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: var(--font-size-verb-mobile);
  -moz-tab-size: 2;
  tab-size: 2;

  @media (min-width: 961px) {
    font-size: var(--font-size-verb);
  }
}

div.highlighter-rouge {
  background-color: var(--color-fast-weiss);
  /* background-color: #f5f8fa; */
}

code.highlighter-rouge {
  color: var(--color-joker);
}

/* #1565c0 leichtes blau
 * #42a5f5 grelles blau
 * hg #212121
 */

.cqe-card {
  /* background-color: #f8f8f8; */
  border-radius: 15px;
  color: var(--color-fast-weiss);

  /* #ff8484 */
  background-color: var(--color-dunkel-0);
  padding: 10px;
  margin: 0 0 2em 15px;
}

a code {
  color: var(--color-primary);
}

pre {
  &.highlight {
    overflow-x: auto;
    line-height: 1.3;
    //border: 1px solid #ddd;
    padding-left: 6px;
    padding-top: 14px;
    padding-bottom: 14px;
    margin-top: 6px;
    margin-bottom: 10px;
    white-space: pre;

    code {
      color: var(--color-dunkel-0);
      font-weight: normal;
    }
  }
}

.nav {
  &-bar {
    background-color: #fff;
    min-height: 60px;
    margin: 0;
    padding-top: 0;
    padding-bottom: 14px;
    position: relative;
  }
  &-text {
    margin: var(--space-1) var(--space-2);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &-content {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
  }

  &-title {
    /* margin: var(--space-2) var(--space-2); */
    font-weight: 700;
    font-size: var(--font-size-huge);
    line-height: 1.3;
    color: var(--color-heading);

    a,
    a:link,
    a:active,
    a:visited {
      color: inherit;
    }
  }

  &-links {
    padding: 0;
    margin: 0;
    align-self: flex-end;
    /* margin-top: var(--space-1); */
    align-items: flex-end;
    /* gap: var(--space-2); */
    /* padding-left: 7px; */
    display: flex;
    flex-wrap: wrap;
    list-style: none outside none;
    line-height: 1.6;
    font-weight: bold;
    font-size: var(--font-size-large);
    overflow: hidden;

    @media (max-width: 641px) {
      line-height: 2;
    }

    div {
      padding-left: var(--space-2);
    }
  }
}

.content {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--space-3);
  padding-right: var(--space-3);
  // https://github.com/edwardtufte/tufte-css/blob/gh-pages/tufte.css
  counter-reset: sidenote-counter;
  hyphens: auto;
}

.lab {
  &-icon {
    vertical-align: text-bottom;
  }

  &-controls {
    display: flex;
    justify-content: space-between;
  }

  &-toc {
    border-radius: 6px;
    width: 280px;
    padding: var(--space-1) var(--space-2);
    margin-right: var(--space-3);
    flex-shrink: 1;
    position: sticky;
    top: var(--space-3);
    background-color: white;
    font-size: var(--font-size-comment-sc);
    h3 {
      margin-top: var(--space-1);
    }
  }

  &-bg {
    background-color: var(--color-dreck-2);
  }

  &-content {
    padding-top: 30px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-bottom: 6rem;
  }

  &-main {
    border-radius: 6px;
    padding: 21px 42px;
    flex-grow: 1;
    background-color: white;
    max-width: 942px;
    margin-right: auto;
  }

  &-main-present {
    padding: 21px 42px;
    /* margin: 0 auto; */
    margin: 0 var(--space-6);
    background-color: white;
    max-width: 1100px;
    width: calc(80% - var(--space-6) - var(--space-6));
  }
}

blockquote > p {
  margin-bottom: 0;
}

.content > .foto {
  margin: 20px;
  text-align: center;
}

@media (max-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .lab-toc {
    display: none;
  }

  .lab-main {
    padding: 21px 14px;
    max-width: 100%;
  }

  .content {
    max-width: 100%;
  }

  .lab-content {
    padding-left: 7px;
    padding-right: 7px;
  }

  .article-content {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
}

a.nav,
a.nav:link,
a.nav:active,
a.nav:visited {
  color: var(--color-primary);
  margin-left: 2px;
  margin-right: 2px;
  white-space: nowrap;
}

.vlist-head {
  margin-right: var(--space-4);
  padding-bottom: var(--space-1);
}

.vlist-sep {
  margin-left: var(--space-1);
  color: var(--color-border);
}

.vlist-tail {
  margin-left: var(--space-1);
}

.active a,
.active a:link,
.active a:active,
.active a:visited {
  color: var(--color-primary-dunkel);
}

li.post-entry-condensed {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

li.post-entry:not(:last-child) {
  padding-bottom: var(--space-2);
}

ul.post-list-condensed {
  list-style-type: none;
  margin-top: 0;
  margin-bottom: var(--space-6);
  margin-left: 0;
  padding-left: 0;
}

ul.post-list {
  list-style-type: none;
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
}

.sidenote,
.marginnote {
  // width: 900 + left 30% + right 30% + space 6
  // width: 1440 + space 6 = 1482
  @media (min-width: 1482px) {
    float: right;
    clear: right;
    // margin RIGHT (not left)
    margin-right: calc(-1 * (var(--space-6) + 30%));
    width: 30%;
    vertical-align: baseline;
    position: relative;
    padding: var(--space-half) 0;
    font-size: 0.85rem;
  }
  @media (max-width: 1455px) {
    display: block;
    width: 95%;
    margin: 1rem 2.5%;
    font-size: 0.9rem;
    /* border-top: 1px solid var(--color-border); */
    /* padding: var(--space-1) var(--space-1); */
  }
}

.sidenote::before {
  content: counter(sidenote-counter) " ";
  font-size: 0.7rem;
  top: -0.5rem;
}

.sidenote-num::after {
  content: counter(sidenote-counter);
  font-size: 0.7rem;
  top: -0.5rem;
  left: 0.1rem;
}

.sidenote-num {
  counter-increment: sidenote-counter;
}

.sidenote-num::after,
.sidenote::before {
  font-family: "et-book-roman-old-style";
  position: relative;
  vertical-align: baseline;
}

.condensed {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: var(--space-half);
  margin-top: var(--space-1);
}

.post-title {
  font-weight: bold;
  color: var(--color-heading);
  display: flex;
  gap: var(--space-half);
  justify-content: flex-start;
}

.sub-section-list {
  margin-left: calc(-1 * var(--space-1));
}

.post-sprache {
  text-transform: uppercase;
  font-size: var(--font-size-comment-sc);
}

.post-content {
  margin-bottom: 30px;
}

.post-info {
  font-size: var(--font-size-comment);
  color: var(--color-dunkel-0);
  white-space: nowrap;
  font-style: italic;
}

div.post-info {
  margin-bottom: 21px;
  margin-left: 0;
  font-weight: bold;
  font-style: italic;
}

.hero-block {
  margin-bottom: 2rem;
}

.kub {
  &-full {
    padding: 0 15px 2em 10px;
    flex-basis: 100%;
    @media (min-width: 641px) {
      flex-basis: 97%;
    }
  }

  &-entry {
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    break-inside: avoid;

    > p {
      /* hyphens */
      -moz-hyphens: inherit;
      -o-hyphens: inherit;
      -webkit-hyphens: inherit;
      -ms-hyphens: inherit;
      hyphens: inherit;
    }
  }

  &-entries-small {
    display: flex;
    flex-wrap: wrap;
  }

  &-entries-full {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &-small {
    padding: 0 var(--space-2) var(--space-1) var(--space-1);

    flex-basis: 100%;

    @media (min-width: 641px) {
      flex-basis: 50%;
    }
  }

  &-title {
    font-size: var(--font-size-large);
    font-weight: 700;
    color: var(--color-dunkel-0);
    margin-bottom: 0.3em;
  }

  &-date-title {
    font-size: var(--font-size-comment-sc);
    font-weight: 700;
    color: var(--color-dunkel-1);
    margin-bottom: 0.3em;
  }
}

blockquote {
  font-style: italic;
  color: var(--color-sehr-dunkel);
  border-left: 2px solid var(--color-dunkel-1);
  margin: 1.5em 10px;
  padding: 0.4em 10px;
}

.section {
  margin-top: 2.3rem;
  margin-bottom: 2rem;
}

.section-heading {
  margin-bottom: 0.9rem;
}

.sub-section {
  margin-bottom: 0.13rem;
}

hr {
  width: 60%;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid var(--color-border);
  margin: 0.7em auto 0.7em auto;
  padding: 0;

  &.kub {
    width: 47%;
    border-top: 1px solid #aaa;
    margin: 0;

    @media (max-width: 641px) {
      margin: 0;
      margin-left: 5px;
      width: 100%;
    }
  }
}

.active-section {
  font-weight: bold;
}

thead tr th {
  border-bottom: 1px solid var(--color-border);
}

::selection {
  background: var(--color-selection);
}

::-moz-selection {
  background: var(--color-selection);
}

/*
details > summary {
    list-style-type: '» ';
}

details[open] > summary {
    list-style-type: '× ';
}
*/

details {
  @extend .shadow;
  padding: var(--space-1);
  margin: var(--space-2);
  margin-bottom: var(--space-4);
}

details > summary {
    list-style-type: none;
    cursor: pointer;
}

details[open] > summary {
    padding-bottom: var(--space-1);
}

details > summary:before {
    content: '+';
    display: inline-block;
    font-weight: bold;
    margin-right: var(--space-half);
    width: var(--space-2);
}

details[open] > summary:before {
    content: '×';
    font-weight: bold;
    display: inline-block;
    margin-right: var(--space-half);
    width: var(--space-2);
}

#footer {
  padding-top: var(--space-2);
  font-size: 0.8rem;
  text-align: right;
}

kbd {
	display: inline-block;
	border-radius: 3px;
	padding: 0px 6px;
	box-shadow: 1px 1px 1px #777;
	margin: 2px;
	vertical-align: text-bottom;
	background: #eee;
	color: #555;
	font-variant: small-caps;
	font-size: var(--font-size-small);;
	font-weight: 600;
}

.seite {
	border-radius: 6px;
	padding: 16px 8px;
	flex-grow: 1;
	background-color: white;
}
.inhalt {
  display: flex;
  gap: 3px;
}

.gist-data {
  max-height: 11em;
}
