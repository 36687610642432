.mb-tiny {
  margin-bottom: var(--space-half);
}

.mt-medium {
  margin-bottom: var(--space-2);
}

.col-2,.col-4,.col-6,.col-8,.col-10,.col-12 {
  width: 100%;
}

@media (min-width: 961px) {
  .col-2 {
    width: 15%;
  }

  .col-4 {
    width: 30%;
  }

  .col-6 {
    width: 45%;
  }

  .col-8 {
    width: 60%;
  }

  .col-10 {
    width: 75%;
  }

  .col-12 {
    width: 90%;
  }
}

.col-max {
  width: 100%;
}

.round {
  border-radius: 0.3rem;
}

/* https://css-tricks.com/snippets/css/text-rotation/ */
.rotate270 {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  filter: progid:dximagetransform.microsoft.basicimage(rotation=3);
}

.rotate90 {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  filter: progid:dximagetransform.microsoft.basicimage(rotation=1);
}
.rotate45 {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  filter: progid:dximagetransform.microsoft.basicimage(rotation=1);
}

.invisible {
  visibility: hidden;
}

.collapse {
  display: none;
}

.paper-effect {
  -webkit-box-shadow: 0 6px 6px -6px #777;
  -moz-box-shadow: 0 6px 6px -6px #777;
  box-shadow: 0 6px 6px -6px #777;
}

.shadow {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
}

.with-hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
