:root {
  --color-selection: #fef4ad;
  --space-half: 3px;
  --space-1: 7px;
  --space-2: 14px;
  --space-3: 21px;
  --space-4: 28px;
  --space-5: 35px;
  --space-6: 42px;
  --space-7: 49px;
  --color-primary: #2062bd;
  --color-primary-dunkel: #0d3770;
  --color-sehr-dunkel: #272723;
  --color-dunkel-0: #3b3a35;
  --color-dunkel-1: #666563;
  --color-dunkel-2: #cacac9;
  /* --color-dunkel-1: #6C6B68; */
  /* --color-dunkel-2: #CECECD; */
  --color-dreck-0: #bfbdac;
  --color-dreck-1: #d4d3c8;
  --color-dreck-2: #eae9e3;
  --color-fast-weiss: #f8f8f6;
  --color-joker: #6f42c1;
  --color-border: #bfbdac;
  --color-heading: var(--color-sehr-dunkel);
  --font-size-huge: 1.33rem;
  --font-size-large: 1.16rem;
  --font-size-normal: 1rem;
  --font-size-verb: 0.72rem;
  --font-size-verb-mobile: 0.75rem;
  --font-size-small: 0.88rem;
  --font-size-comment: 0.85em;
  --font-size-comment-sc: 0.91em;
}
